<template lang="pug">
	main.faq
		.card.card--colapsed(v-for="faq in faqs" :key="faq.title" v-on:click="open")
			.card-header {{ faq.title }}
			.card-expanded {{ faq.description }}
</template>

<script>
import faqs from '@/assets/json/faqs.json'

export default {
	data(){
		return{
			faqs: faqs
		}
	},
	methods: {
		open: function(event) {
			event.target.parentElement.classList.toggle("card--colapsed")
		}
	}
}
</script>

<style lang="scss" scoped>
	.faq {
		display: flex;
		flex-direction: column;
		font-weight: 600;
		font-size: 16px;
		align-items: center;
		padding: 30px 10%;

		@media only screen and (max-width: 650px) {
			padding: 20px;
		}

		.card {
			width: 100%;
			box-shadow: 0px 0px 8px #00000033;
			border-radius: 10px;
			margin-bottom: 20px;

			.card-header, .card-expanded {
				padding: 20px 30px;
			}

			.card-header {
				background-color: #2060D9;
				color: white;
				box-shadow: 0px 0px 8px #00000033;
				border-radius: 10px;
				font-weight: bold;
				user-select: none;
			}

			.card-expanded {
				overflow: hidden;
				height: unset;
				transition: width 600ms ease-out, height 600ms ease-out;
			}

			&.card--colapsed {
				.card-header {
					background-color: white;
					color: black;
				}

				.card-expanded {
					height: 0;
					padding: 0;
				}
			}
		}
	}
</style>
